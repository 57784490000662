import { combineReducers } from "redux";
import SettingsReducer from './settings/reducer'
import ProfilesReducer from './profile/reducer';

const reducers = combineReducers({
    SettingsReducer,
    ProfilesReducer,
});

export default reducers;
