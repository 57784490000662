import BookmarkButton from "./BookmarkButton";
import ProfileDetail from "./ProfileDetail";
import Modal from "../Modal";
import { useState } from "react";

function ProfilePreview({ profileData }) {
  var statusBadges = [];
  const [isOpen, setIsOpen] = useState(false);

  if (profileData?.stats?.orientation !== "Ask me") {
    statusBadges.push(
      <span
        key={profileData?.stats?.orientation}
        className={`bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-800 dark:text-emerald-200`}
      >
        {profileData?.stats?.orientation}
      </span>
    );
  }
  if (profileData?.stats?.position !== "Ask me") {
    statusBadges.push(
      <span
        key={profileData?.stats?.position}
        className={`bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-800 dark:text-emerald-200`}
      >
        {profileData?.stats?.position === "Versatile / Top"
          ? "Vers Top"
          : profileData?.stats?.position === "Versatile / Bottom"
          ? "Vers Bottom"
          : profileData?.stats?.position}
      </span>
    );
  }
  if (profileData?.stats?.cock_cut !== "Ask me") {
    statusBadges.push(
      <span
        key={profileData?.stats?.cock_cut}
        className={`bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-800 dark:text-emerald-200`}
      >
        {profileData?.stats?.cock_cut}
      </span>
    );
  }

  return (
    <div className="p-4 w-100 bg-white mx-auto rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
      <Modal
        title={profileData?.username}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <ProfileDetail username={profileData?.username} />
      </Modal>

      <div className="flex mb-4 justify-between">
        <a
          href={profileData?.profile_url?.replace("app.rent.men", "rent.men")}
          target="_blank"
          rel="noreferrer"
          type="button"
          className="flex items-center px-4 py-2 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-800 dark:text-slate-300 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 copy-to-clipboard-button"
        >
          <svg
            className="w-4 h-4 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
            />
          </svg>{" "}
          <span className="copy-text">Visit Profile</span>
        </a>
        <BookmarkButton
          username={profileData?.username}
          profileInfo={profileData}
        />
      </div>
      <div className="flex items-center mb-2">{statusBadges}</div>

      <div className="flex flex-shrink-0 justify-center">
        <button
          type="button"
          data-copy-state="copy"
          className=""
          onClick={() => setIsOpen(!isOpen)}
        >
          <img
            className="object-cover rounded h-50"
            style={{ height: "350px" }}
            src={profileData?.photo || ""}
            alt="Preview"
          />
        </button>
      </div>

      <div className="flex flex-col justify-between py-4 leading-normal">
        <h5 className="mb-2 text-md font-bold tracking-tight text-gray-900 dark:text-white">
          {profileData?.username}
          <span className="text-sm font-light text-gray-700 truncate dark:text-slate-400">
            {` (${profileData.city} `}
            {profileData?.stats?.age
              ? ", " + profileData?.stats?.age + " Y/O"
              : null} {")"}
          </span>
        </h5>
        <p
          className="mb-2 text-sm font-semibold text-gray-700 dark:text-gray-400 line-clamp-1"
          dangerouslySetInnerHTML={{
            __html: decodeURIComponent(escape(profileData?.headline)),
          }}
        />
        <p
          className="mb-2 text-sm font-normal text-gray-700 dark:text-gray-400"
          dangerouslySetInnerHTML={{
            __html: decodeURIComponent(
              escape(profileData?.description + "...")
            ),
          }}
        />
      </div>
    </div>
  );
}
export default ProfilePreview;
