const initialState = {
  profileFetchErr: null,
  profiles: [],
  usernameToProfileDetail: new Map(),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_PROFILES":
      return { ...state, profiles: action.profiles, profileFetchErr: null };
    case "APPEND_PROFILES":
      return {
        ...state,
        profiles: [...state.profiles, ...action.profiles],
        profileFetchErr: null,
      };
    case "SET_PROFILE_FETCH_ERROR":
      return { ...state, profileFetchErr: action.err };
    case "SET_PROFILE_DETAIL": {
      let newMapping = state.usernameToProfileDetail;
      let oldData = newMapping.get(action.username) || {};
      newMapping.set(action.username, { ...oldData, ...action.data });
      return { ...state, usernameToProfileDetail: newMapping };
    }
    default:
      return { ...state };
  }
}
