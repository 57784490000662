// Copyright 2022 Gan Tu
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useProfileDetail } from "../../hooks/database";
import ImageGallery from "react-image-gallery";
import ReactPlayer from "react-player";

export default function ProfileDetailModal({ username }) {
  const dispatch = useDispatch();
  const profileData = useProfileDetail(username);
  const [showPhoto, setShowPhoto] = useState(true);
  const [showVideo, setShowVideo] = useState(true);

  useEffect(() => {
    if (!profileData) {
      dispatch({
        type: "FETCH_PROFILE_DETAIL",
        username,
      });
    }
  }, [dispatch, username, profileData]);

  let mediaFiles = [];

  if (showPhoto) {
    let photos =
      profileData?.photos?.map((url) => {
        return {
          original: url,
          thumbnail: url,
          renderItem: () => {
            return (
              <a type="button" target="_blank" rel="noreferrer" href={url}>
                <img
                  className="object-contain center mx-auto rounded w-96 max-h-96 pb-5"
                  src={url}
                  alt={username}
                />
              </a>
            );
          },
          renderThumbInner: () => {
            return (
              <img
                className="object-contain center mx-auto rounded w-20 max-h-20"
                src={url}
                alt={username}
              />
            );
          },
        };
      }) || [];
    mediaFiles = [...mediaFiles, ...photos];
  }

  if (showVideo) {
    let videos =
      profileData?.videos?.map((data) => {
        return {
          original: data?.thumbnail,
          thumbnail: data?.thumbnail,
          renderItem: () => {
            return (
              <ReactPlayer
                url={data?.video_url}
                width="100%"
                height="400px"
                playsinline={true}
                style={{
                  objectFit: "contain",
                  maxWidth: "100%",
                  maxHeight: "600px",
                  margin: "auto",
                  paddingBottom: "5%",
                }}
                className="mx-auto rounded"
                controls={true}
              />
            );
          },
          renderThumbInner: () => {
            return (
              <img
                className="object-contain center mx-auto rounded w-20 max-h-20"
                src={data?.thumbnail}
                alt={username}
              />
            );
          },
        };
      }) || [];
    mediaFiles = [...mediaFiles, ...videos];
  }

  return (
    <form className="mt-5">
      <div className="space-y-4">
        <div className="flex items-center space-x-4">
          <div className="flex-1 min-w-0 w-60 lg:w-80 space-y-1">
            {mediaFiles.length ? (
              <ImageGallery
                items={mediaFiles}
                lazyLoad={true}
                showPlayButton={false}
                showFullscreenButton={false}
                showBullets={mediaFiles.length <= 20}
                infinite={true}
                autoPlay={false}
              />
            ) : (
              <p className="text-sm py-5 text-center text-gray-600 dark:text-gray-400">
                No media files found.
              </p>
            )}

            <div className="flex justify-start pb-2 gap-4">
              <a
                href={profileData?.profile_url?.replace(
                  "app.rent.men",
                  "rent.men"
                )}
                target="_blank"
                rel="noreferrer"
                type="button"
                className="flex items-center px-4 py-2 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-800 dark:text-slate-300 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 copy-to-clipboard-button"
              >
                <svg
                  className="w-4 h-4 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                  />
                </svg>{" "}
                <span className="copy-text">Visit Profile</span>
              </a>
              {profileData?.photos?.length > 0 && (
                <button
                  className="flex items-center px-4 py-2 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-800 dark:text-slate-300 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 copy-to-clipboard-button"
                  onClick={(e) => {
                    setShowPhoto(!showPhoto);
                    e.preventDefault();
                  }}
                >
                  <span>{showPhoto ? "Hide" : "Show"} Photos</span>
                </button>
              )}
              {profileData?.videos?.length > 0 && (
                <button
                  className="flex items-center px-4 py-2 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-800 dark:text-slate-300 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 copy-to-clipboard-button"
                  onClick={(e) => {
                    setShowVideo(!showVideo);
                    e.preventDefault();
                  }}
                >
                  <span>{showVideo ? "Hide" : "Show"} Videos</span>
                </button>
              )}
            </div>

            {profileData?.headline && (
              <p
                className="text-md font-semibold text-gray-900 pb-5 line-clamp-1 text-ellipsis overflow-hidden dark:text-white"
                dangerouslySetInnerHTML={{
                  __html: decodeURIComponent(
                    escape(profileData?.headline.replaceAll("\n", "<br/>"))
                  ),
                }}
              />
            )}

            {profileData?.description && (
              <p
                className="text-sm text-gray-600 dark:text-gray-400"
                dangerouslySetInnerHTML={{
                  __html: decodeURIComponent(
                    escape(profileData?.description.replaceAll("\n", "<br/>"))
                  ),
                }}
              />
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
