import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

// Make objects filterable
Object.filter = (obj, predicate) =>
  Object.fromEntries(Object.entries(obj).filter(predicate));

function isUsernameBookmarked(username) {
  return localStorage.getItem(`profile-${username}`) !== null;
}

function useProfileDetail(username) {
  return useSelector((store) =>
    store.ProfilesReducer.usernameToProfileDetail.get(username)
  );
}

function useFilteredProfiles() {
  const profiles = useSelector((store) => store.ProfilesReducer.profiles);
  const filters = useSelector((store) => store.SettingsReducer.filter);
  const [filteredProfiles, setFilteredProfiles] = useState(profiles);

  useMemo(async () => {
    return setFilteredProfiles(
      profiles.filter((data) => {
        if (
          filters.profileName &&
          !data.username
            .toLowerCase()
            .includes(filters.profileName.toLowerCase())
        ) {
          return false;
        }
        if (filters.maxAge && data?.stats.age > filters.maxAge) {
          return false;
        }
        if (
          filters.position &&
          !data?.stats.position.includes(filters.position)
        ) {
          return false;
        }
        if (
          filters.orientation &&
          !data?.stats.orientation.includes(filters.orientation)
        ) {
          return false;
        }
        if (filters.cock_cut && data?.stats.cock_cut !== filters.cock_cut) {
          return false;
        }
        // Bookmark is different and the state is saved in localStorage
        if (
          filters.marked !== null &&
          !!filters.marked !== !!isUsernameBookmarked(data?.username)
        ) {
          return false;
        }
        return true;
      })
    );
  }, [profiles, filters]);

  return filteredProfiles;
}

export { useFilteredProfiles, useProfileDetail, isUsernameBookmarked };
