import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";
import toast from "react-hot-toast";

function* markProfileImpl({ username, profileInfo }) {
  yield localStorage.setItem(
    `profile-${username}`,
    JSON.stringify(profileInfo)
  );
}

function* unmarkProfileImpl({ username }) {
  yield localStorage.removeItem(`profile-${username}`);
}

function searchProfile(city, pageNum) {
  return axios
    .get(
      `https://app.rent.men/api/gay-escorts/ajax/${city}?page=${pageNum || 1}`
    )
    .then(({ data }) => {
      return (
        data?.users?.map((userData) => {
          let userProfileData = {
            username: userData?.username,
            user_id: userData?.user_id,
            photo: `https:${userData?.photo}`,
            profile_url: `https://rent.men/${userData?.link}`,
            headline: userData?.headline,
            description: userData?.description,
            city: userData?.city,
            stats: {
              age: userData?.age,
              cock_cut: userData?.cock_cut,
              position: userData?.position,
              orientation: userData?.orientation,
            },
          };
          return userProfileData;
        }) || []
      );
    })
    .catch((err) => {
      console.error(`Failed to search profile data for ${city}: ${err}`);
    });
}

function* searchProfilesImpl({ city, page }) {
  if (!page) {
    let curPage = 1;
    let toastId = toast.loading("Start fetching profiles of all pages..");
    if (!city) {
      toast("Fetching profiles for all cities");
    }
    while (true) {
      if (curPage === 1) {
        yield put({ type: "SET_PROFILES", profiles: [] });
      }
      toastId = toast.loading(`Fetching page ${curPage}`, {
        id: toastId,
      });
      const profiles = yield call(searchProfile, city, curPage);
      yield put({ type: "APPEND_PROFILES", profiles });
      if (profiles.length === 0) {
        break;
      }
      curPage += 1;
    }
    toast.success(`Finished fetching all ${curPage} pages.`, { id: toastId });
  } else {
    const toastId = toast.loading(`Fetching page number ${page}`);
    if (page === 1) {
      if (!city) {
        toast("Fetching profiles for all cities");
      }
      yield put({ type: "SET_PROFILES", profiles: [] });
    }
    const profiles = yield call(searchProfile, city, page);
    toast.success(`Fetched ${profiles.length} profiles for page ${page}`, {
      id: toastId,
    });
    yield put({ type: "APPEND_PROFILES", profiles });
  }
}

function fetchProfile(username) {
  return axios
    .get(`https://app.rent.men/api/profile/${username}`)
    .then(({ data }) => {
      const profileData = {
        name: username,
        user_id: data?.userData?.user_id,
        profile_url: `https://rent.men/${username}`,
        headline: data?.userData?.headline || "No headline",
        description: data?.userData?.description || "No description",
        photos: [],
        videos: [],
      };
      // add photos
      let userPhotos = data?.userPhotos || [];
      if (userPhotos && userPhotos?.success) {
        let photo_root = userPhotos.paths?.big || userPhotos.paths?.main;
        userPhotos = userPhotos.public?.map((photoData) => {
          return `${photo_root}/${photoData.folder}/${photoData.file}`;
        });
      }
      profileData.photos = userPhotos || [];
      // add videos
      let videos = data?.videos?.map((video_data) => {
        let thumbnail = video_data.thumbnail;
        let folder = video_data.folder;
        let idx = thumbnail.indexOf(folder);
        return {
          thumbnail: thumbnail,
          video_url: `${thumbnail.slice(0, idx)}/${folder}/${folder}.mp4`,
        };
      });
      profileData.videos = videos || [];
      return profileData;
    })
    .catch((err) => {
      console.error(`Failed to get profile data for ${username}: ${err}`);
    });
}

function* fetchProfileDetailImpl({ username }) {
  const profileData = yield call(fetchProfile, username);
  yield put({
    type: "SET_PROFILE_DETAIL",
    username,
    data: profileData,
  });
}

export function* watchProfilesApp() {
  yield takeLatest("SEARCH_PROFILES", searchProfilesImpl);
  yield takeLatest("FETCH_PROFILE_DETAIL", fetchProfileDetailImpl);
  yield takeEvery("MARK_PROFILE_INFO", markProfileImpl);
  yield takeEvery("UNMARK_PROFILE_INFO", unmarkProfileImpl);
}
