import { useDispatch } from "react-redux";
import Trilean from "./Trilean";
import NumericInput from "./NumericInput";
import DropdownSelect from "./DropdownSelect";
import SearchBox from "./SearchBox";

function SettingsPanel() {
  const dispatch = useDispatch();

  let handleRememberFilter = (e) => {
    dispatch({ type: "SAVE_FILTERS" });
  };

  return (
    <div id="filterForm">
      <div className="my-6 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <DropdownSelect
          id="cock_cut"
          label="Cock Cut"
          options={["Cut", "Uncut"]}
        />
        <DropdownSelect
          id="position"
          label="Position"
          options={[
            "Top",
            "Versatile / Top",
            "Versatile",
            "Versatile / Bottom",
            "Bottom",
            "Ask me",
          ]}
        />
        <DropdownSelect
          id="orientation"
          label="Orientation"
          options={["Gay", "Bisexual", "Straight", "Transgender", "Ask me"]}
        />
        <Trilean id="marked" label="Bookmarked" />
        <NumericInput id="maxAge" label="Max Age" />
        <SearchBox id="profileName" label="Profile Name" />
      </div>

      <button
        className="text-white bg-green-400 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-100 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-400 dark:focus:ring-green-600"
        onClick={handleRememberFilter}
      >
        Save Filters
      </button>
    </div>
  );
}

export default SettingsPanel;
