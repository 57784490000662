import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useFilteredProfiles } from "./hooks/database";
import ProfilePreview from "./components/ProfilePreview";
import SettingsPanel from "./components/SettingsPanel";
import StatusMessage from "./components/StatusMessage";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHasFilter } from "./hooks/settings";

function App() {
  const dispatch = useDispatch();
  const profiles = useFilteredProfiles();
  const [cityInput, setCityInput] = useState("");
  const [nextPageNum, setNextPageNum] = useState(2);
  const hasFilter = useHasFilter();
  const quickSearchCities = {
    "New York": "newyork",
    "Los Angeles": "losangeles",
    "San Francisco": "sanfrancisco",
    Boston: "boston",
    Chicago: "chicago",
  };

  useEffect(() => {
    dispatch({ type: "LOAD_DEFAULT_FILTERS" });
  }, [dispatch]);

  useEffect(() => {
    let profilesCount = profiles?.length || 0;
    if (profilesCount) {
      dispatch({
        type: "SET_STATUS_MESSAGE",
        msg: `Displaying ${profilesCount} (filtered) profiles.`,
      });
    } else {
      dispatch({
        type: "SET_STATUS_MESSAGE",
        msg: `No profiles available for display.`,
      });
    }
  }, [dispatch, profiles]);

  const onSearch = (city, page) => {
    if (hasFilter) {
      dispatch({
        type: "SEARCH_PROFILES",
        city: city.replace(" ", "").toLowerCase(),
      });
    } else {
      setNextPageNum(page + 1);
      dispatch({
        type: "SEARCH_PROFILES",
        city: city.replace(" ", "").toLowerCase(),
        page: page,
      });
    }
  };

  return (
    <div className="bg-white dark:bg-slate-900 min-h-screen">
      <div className="mx-auto px-10 md:px-6 lg:px-3 md:max-w-xl lg:max-w-3xl pt-12">
        <form autoComplete="off">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
          >
            Search
          </label>
          <div className="relative">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="city-search"
              className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder={cityInput || "Enter City Name"}
              value={cityInput}
              onChange={(e) => {
                setCityInput(e.target.value);
                e.preventDefault();
              }}
              required
            />
            <button
              type="submit"
              className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={(e) => {
                onSearch(cityInput, 1);
                e.preventDefault();
              }}
            >
              Search Profiles
            </button>
          </div>
        </form>

        <div className="mt-5 gap-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 text-center">
          {Object.keys(quickSearchCities).map((city) => {
            return (
              <button
                className="flex items-center px-4 py-3 text-center text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-800 dark:text-slate-300 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 copy-to-clipboard-button"
                key={city}
                onClick={() => {
                  setCityInput(city);
                  onSearch(quickSearchCities[city], 1);
                }}
              >
                <span>{city}</span>
              </button>
            );
          })}
        </div>

        <SettingsPanel />
        <StatusMessage />
      </div>

      <div className="px-12 md:px-6 mx-auto md:max-w-max pb-12">
        <InfiniteScroll
          dataLength={profiles.length} //This is important field to render the next data
          next={() => {
            onSearch(cityInput, nextPageNum);
          }}
          hasMore={!hasFilter}
          // loader={cityInput ? <h4>Loading...</h4> : null}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <div
            className="my-6 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  2xl:grid-cols-5 gap-4"
            id="profileList"
          >
            {profiles.map((profile, idx) => (
              <ProfilePreview key={`profile-${idx}`} profileData={profile} />
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default App;
